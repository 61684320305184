import React, { useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, Text } from '../components/primitives'

export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Cookie policy | Hemset.se'}
     >
      <Flex jusifyContent={'center'}>
        <Flex
          mt={['80px', '80px', '120px']}
          maxWidth={'1000px'}
          minHeight={'100vh'}
          p={4}
          flexDirection={'column'}>
          <Text fontWeight={'bold'} fontSize={8} mb={2}>
            Denna webbplats använder cookies
          </Text>
          <Text mb={2}>
            En cookie eller kaka är en textfil som webbservern ber om att få
            lagra på besökarens dator. Textfilen gör det möjligt att spara
            personliga inställningar så att dessa inte behöver anges på nytt vid
            nästa besök på webbplatsen. Den används också för att samla in
            statistik, exempelvis typ av webbläsare, antalet besök och
            sidvisningar. Statistiken ger en bild av webbplatsens besökare och
            används även som underlag vid utvecklingen av nya funktioner och
            förbättringar. All information som samlas in är anonym. Ibland
            används även cookies från andra företag, bland annat för att
            genomföra marknadsundersökningar och trafikmätningar. Dessa
            textfiler kommer således från samarbetspartners och
            teknikleverantörer till Hemset.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Syften
          </Text>
          <Text>1. Lagra personliga inställningar</Text>
          <Text>
            2. Förbättra webbplatsen genom att analysera hur den används
          </Text>
          <Text>3. räkna webbplatsens användarantal och räckvidd</Text>
          <Text mb={2}>
            4. alys av trafikflöden med tredjepartsverktyg som Google Analytics
          </Text>
          <Text>
            Det går att stänga av eller begränsa webbplatsens användning av
            cookies genom att justera inställningarna i webbläsaren. Hur detta
            går till varierar beroende på vilken webbläsare som används.
            Dokumentation till webbläsaren finns vanligtvis i en särskild
            hjälp-meny. Observera att med cookies avstängda kan vissa funktioner
            upphöra att fungera. Vid frågor eller andra synpunkter på Hemset’s
            hantering av cookies, vänligen kontakta oss.
          </Text>
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
